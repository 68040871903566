.ServerTitle {
    margin-bottom: 1em;
}

.ServerHeading {
    margin-bottom: 0;
}

.ServerLogo {
    vertical-align: sub;
}
