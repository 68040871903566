.scrollable{
    overflow-y: scroll;
    max-height: 28rem;
}
.scrollable_short{
    overflow-y: scroll;
    max-height: 5rem;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.scrollable_short::-webkit-scrollbar {
    display: none;
}