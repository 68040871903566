.modal__wrap {
    position: fixed;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(var(--bluelib-background-r), var(--bluelib-background-g), var(--bluelib-background-b), .75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 250ms ease;
}

.visible {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease;
}